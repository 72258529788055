import { WhatsAppOfficialState } from './state'

export const totalCredits = (state: WhatsAppOfficialState) => {
  const whatsApp = state?.balancies?.whatsapp ?? 0
  const general = state?.balancies?.general ?? 0
  return whatsApp + Math.max(0, general)
}

export const whatsAppOfficialBalance = (state: WhatsAppOfficialState) =>
  state.balancies.whatsapp

export const allBalancies = (state: WhatsAppOfficialState) => state.balancies

export const isWhatsAppOfficialBalanceLoading = (
  state: WhatsAppOfficialState
) => state.isLoading

export const isWhatsAppOfficialBalanceNegative = (
  state: WhatsAppOfficialState
) => state.balancies.whatsapp < 0

export const hasWhatsAppOfficialBalanceError = (state: WhatsAppOfficialState) =>
  state.hasError

export const balanceLastCheck = (state: WhatsAppOfficialState) =>
  state.lastCheck
