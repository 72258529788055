import { NavigationGuardNext } from 'vue-router'

import { RoleTypes } from '@/common/helpers/enums/role-types'

import store from '@/store'

const handleRedirectLandingPage = (to: any, next: NavigationGuardNext) => {
  const hasWozCopilotFeature = store.getters.isWozEnabled

  if (!hasWozCopilotFeature) next({ name: 'woz-lp' })
  else next()
}

export default {
  path: '/woz',
  name: 'woz',
  beforeEnter: (to: any, from: any, next: any) => {
    handleRedirectLandingPage(to, next)
  },
  redirect: '/woz/general',
  component: () => import('@/views/Woz.vue'),
  children: [
    {
      path: 'general',
      name: 'general',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () => import('./general/components/General.vue')
    },
    {
      path: 'content',
      name: 'content',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () => import('./content/components/Content.vue')
    },
    {
      path: 'woz-agents',
      name: 'woz-agents',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () => import('./agents/components/Agents.vue')
    },
    {
      path: 'woz-agents-edit',
      name: 'woz-agents-edit',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      props: (route: any) => {
        return {
          agentId: route.params.agentId,
          agentName: route.params.agentName
        }
      },
      component: () => import('./agents/components/edit/AgentsEdit.vue')
    }
  ]
}
